<!--学生管理-历史成绩列表-->
<template>
  <list-template
      :total="total"
      :table-data="tableData"
      :table-config="tableConfig"
      has-back
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
  ></list-template>
</template>

<script>
export default {
	_config:{"route":{"path":"achievement","meta":{"title":"历史成绩","style":1}}},
  data(){
    return {
      query: '',

      // 表格配置
      tableConfig: [],
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData:[],
      id:null
    }
  },
  mounted() {
    this.query = this.$route.query;
    this.getData();
  },
  methods:{
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e;
      this.getData();
    },
    getData(){

        let id = this.$route.query.id;
        this.$_axios.get("/students/details?id=" + id).then(res => {
          let {data} = res.data;

          let tableConfig = [
            {label: '考试名称', prop: 'exam_name', showOverflowTooltip: true, minWidth: '200rem'},
            {label: '考试类型', prop: 'exam_type_name', minWidth: '110rem'},
            {label: '排名', prop: 'rank', minWidth: '110rem'},
            {label: '总成绩', prop: 'score'},
          ]

          data.subject_class.forEach(item => {
            tableConfig.push({
              label: item.subject_name,
              prop: item.subject_name
            })
          })
          tableConfig.push({
            prop:"",
            label:"操作",
            handle:true,
            minWidth: '120',
            showOverflowTooltip: false,
            render:()=>["查看成绩分析"]
          })
          this.tableConfig = tableConfig
        })


      this.$_axios.get("/students/history",{params: {id: this.query.id,page:this.page}}).then(res=>{
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    },

    // 表格后面操作被点击
    tableHandle(row,text,index){
      this.$router.push({path:'./achievement-analysis',
        query:{
          student_no:this.query.student_no ,
          exam_id: row.exam_id
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
